/* eslint-disable react/jsx-pascal-case */
import React, { memo, useMemo } from 'react';
import { bool, shape, string, arrayOf } from 'prop-types';
import classNames from 'classnames/bind';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { AppContext } from '@thd-nucleus/app-render';
import { CustomerContext, useThdCustomer } from '@thd-olt-functional/customer-information';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { declareContexts } from '@thd-olt-component-react/hydrator';
import { Typography } from '@one-thd/sui-atomic-components';
import {
  getContentfulTargetingIds,
  useVariationIds,
} from '@thd-olt-component-react/personalization-utils';
import { componentMapper } from '../dataModel/componentMapper';
import { parseContent, parseSponsoredContent } from '../utils/utilities';
import { EducationalRenderResolver } from './components/EducationalRenderResolver';
import styles from './educational-layout.scss';

/**
 * Main job of this component is to just render the various harmony components only when necessary (see react pure
 * components).
 */
export const EducationalLayoutRenderer = memo(({
  canonicalURL, data, filterBreadcrumbs, pageId, customerType, storeId, pageTypeComponents, search, thdSEOBotDetection, isPreviewLink
}) => {

  declareContexts([AppContext, CustomerContext]);
  const thdCustomer = useThdCustomer();
  const cx = classNames.bind(styles);
  const layouts = data?.layouts;
  const content = layouts?.content;
  const experienceHasSideNav = !!content?.sideNavigation?.id;

  const { mboxes, campaignIds, innervateTags } = getContentfulTargetingIds({ content });

  const isReady = mboxes.length > 0 || campaignIds.length > 0 || innervateTags.length > 0;

  const { variationIds, renderDefault } = useVariationIds({
    mboxes,
    campaignIds,
    innervateTags,
    isReady,
  });

  const customProps = {
    ComponentSelector: (component) => ({
      componentMap: componentMapper.getComponentMap(),
      defaultComponent: component.defaultComponent,
      variations: component.variations,
      variationIds: variationIds || [],
      renderDefault,
      isSeoBot: thdSEOBotDetection,
      targetingType: component.targetingType,
      customPropMap: {
        Spotlight: {
          showDescription: true
        },
        SpotlightContainer: {
          showDescription: true
        },
        HeroPersonalized: {
          thdPageId: pageId,
          searchParams: search,
          isSeoBot: thdSEOBotDetection,
          customerType
        },
        Hero: {
          hasSidenav: experienceHasSideNav
        }
      }
    }),
    Spotlight: () => ({
      showDescription: true
    }),
    SpotlightContainer: () => ({
      showDescription: true
    }),
    HeroPersonalized: () => ({
      thdPageId: pageId,
      searchParams: search,
      isSeoBot: thdSEOBotDetection,
      customerType
    }),
    PromoVisualNavigation: () => ({
      experienceHasSideNav
    }),
    ProductShelf: () => ({
      showTooFewProductsNotice: isPreviewLink
    })
  };

  const { Content } = useMemo(() => parseContent({
    data,
    componentMapper,
    customProps,
    layout: 'educational',
  }), [data, variationIds, renderDefault, search]);

  const { Sponsored } = useMemo(() => parseSponsoredContent({
    data,
    componentMapper,
    customProps,
    layout: 'educational',
  }), [data]);

  const pageTitle = layouts?.title || '';
  const descriptionText = content?.descriptionNew?.descriptionText || '';
  const descriptionTitle = content?.descriptionNew?.title || '';
  const bottomCarouselCustomRenderId = content?.sponsoredBottomCarousel?.customRenderId;
  const middleBannerCustomRenderId = content?.sponsoredMiddleBanner?.customRenderId;
  const skyscraperBannerCustomRenderId = content?.sponsoredSkyscraperBanner?.customRenderId;
  const topBannerCustomRenderId = content?.sponsoredTopBanner?.customRenderId;
  const sponsoredBrowserId = thdCustomer?.mcvisID || '';

  let contentfulDefaultBanner = {};

  if (content?.sponsoredMiddleBanner !== null) {
    contentfulDefaultBanner = content?.sponsoredMiddleBanner;
  } else if (content?.sponsoredTopBanner !== null) {
    contentfulDefaultBanner = content?.sponsoredTopBanner;
  }

  const { landingPageBreadcrumbs, landingPageMetadata } = pageTypeComponents;

  return (
    <Row name="educational-layout-manager">
      <ErrorBoundary name="metadata">
        {landingPageMetadata}
      </ErrorBoundary>
      <Row className="isBound">
        <Col xs="12" className={cx('educational-layout__no-bottom-padding')}>
          <ErrorBoundary name="breadcrumbs">
            {landingPageBreadcrumbs}
          </ErrorBoundary>
        </Col>
        <Col xs="12" className={cx('educational-layout__no-top-bottom-padding')}>
          <ErrorBoundary name="sponsored-top-banner">
            <Sponsored.sponsoredTopBanner
              browserId={sponsoredBrowserId}
              slugId={pageId}
              customRenderId={topBannerCustomRenderId}
              contentfulDefaultBanner={contentfulDefaultBanner}
              schema="cat_sponsored"
            />
          </ErrorBoundary>
        </Col>
        <Col xs="12">
          {pageTitle && (
            <ErrorBoundary name="heading">
              <Typography variant="h1" weight="display" height="snug" color="primary" uppercase>
                {pageTitle}
              </Typography>
            </ErrorBoundary>
          )}
          <Row className={`isBound ${!experienceHasSideNav && ' sui-justify-center'}`}>
            {experienceHasSideNav && (
              <Col sm="2" md="2" lg="2">
                <ErrorBoundary name="side-navigation">
                  <div className={cx('educational-layout__side-navigation')}>
                    <Content.sideNavigation />
                  </div>
                </ErrorBoundary>
                <ErrorBoundary name="sponsored-skyscraper-banner">
                  <Sponsored.sponsoredSkyscraperBanner browserId={sponsoredBrowserId} slugId={pageId} customRenderId={skyscraperBannerCustomRenderId} schema="cat_sponsored" />
                </ErrorBoundary>
              </Col>
            )}
            <Col xs="12" sm="10" md="10" lg="10">
              <ErrorBoundary name="hero-carousel">
                <Content.heroCarousel />
              </ErrorBoundary>
              <EducationalRenderResolver componentId="promo-visual-navigation-1">
                <Content.promoVisualNavigation1 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="promo-visual-navigation-2">
                <Content.promoVisualNavigation2 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="promo-visual-navigation-3">
                <Content.promoVisualNavigation3 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="endcap">
                <Content.endCap
                  storeId={storeId}
                  customerType={customerType.toUpperCase()}
                  pageUrl={`/${pageId}`}
                  narrowBottom
                />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="internal-marketing-banner-1">
                <Content.internalMarketingBanner1 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="spotlight-section">
                <Content.spotlightSection slidesLg={2} slidesXl={3} slidesMd={2} slidesSm={1} />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="sponsored-middle-banner">
                <Sponsored.sponsoredMiddleBanner
                  browserId={sponsoredBrowserId}
                  slugId={pageId}
                  customRenderId={middleBannerCustomRenderId}
                  contentfulDefaultBanner={contentfulDefaultBanner}
                  schema="cat_sponsored"
                />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="internal-marketing-banner-2">
                <Content.internalMarketingBanner2 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="visual-navigation-1">
                <Content.visualNavigation1 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="visual-navigation-2">
                <Content.visualNavigation2 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="visual-navigation-3">
                <Content.visualNavigation3 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="internal-marketing-banner-3">
                <Content.internalMarketingBanner3 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="special-buy-banner">
                <Content.specialBuyOfTheDay specialBuyType="sbotd" />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="product-shelf">
                <Content.productShelf />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="resource">
                <Content.resourcesSection />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="medio-inline-1">
                <Content.medioInline1 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="piccola-pencil-1">
                <Content.piccolaPencil1 />
              </EducationalRenderResolver>
              {Content.dynamicSections?.map((SubSection, key) => (
                <EducationalRenderResolver componentId={`dynamic-section-${key}`} key={`dynamic-section-${key}`}>
                  <SubSection />
                </EducationalRenderResolver>
              ))}
              <EducationalRenderResolver componentId="medio-inline-2">
                <Content.medioInline2 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="piccola-pencil-2">
                <Content.piccolaPencil2 />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="sponsored-bottom-carousel">
                <Sponsored.sponsoredBottomCarousel
                  plaLocation="sponsoredCarouselLandingPage"
                  browserId={sponsoredBrowserId}
                  breadCrumbs={filterBreadcrumbs}
                  customRenderId={bottomCarouselCustomRenderId}
                  pageContext={{
                    schema: 'cat_sponsored'
                  }}
                  nValue={pageId}
                />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="description">
                <Content.descriptionNew title={descriptionTitle} description={descriptionText} />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="related-search">
                <Content.relatedSearchesAndProducts navParam={pageId} />
              </EducationalRenderResolver>
              <EducationalRenderResolver componentId="seo-links">
                {canonicalURL && <Content.seoLinks path={canonicalURL} />}
              </EducationalRenderResolver>
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
});

EducationalLayoutRenderer.displayName = 'EducationalLayoutRenderer';

EducationalLayoutRenderer.propTypes = {
  canonicalURL: string,
  data: shape({}).isRequired,
  filterBreadcrumbs: arrayOf(shape(
    {
      browseUrl: string,
      label: string,
    },
  )),
  pageId: string.isRequired,
  storeId: string.isRequired,
  customerType: string.isRequired,
  pageTypeComponents: shape({}).isRequired,
  search: string,
  thdSEOBotDetection: bool,
  isPreviewLink: bool
};

EducationalLayoutRenderer.defaultProps = {
  canonicalURL: null,
  filterBreadcrumbs: null,
  search: '',
  thdSEOBotDetection: false,
  isPreviewLink: false
};
