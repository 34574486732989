import React, { useState, useEffect, useContext } from 'react';
import { string, bool, shape } from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import classNames from 'classnames/bind';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { initImpressionWatchers } from '../../utils/initImpressionWatchers';
import { makeApiRequest, shouldMakeApiRequest } from '../../utils/requestHelpers';
import {
  checkIfPageIsInAdServerTest,
  getSchema,
  getSkus,
  triggerNewRelic,
  triggerNewRelicTimeLog
} from '../../utils/helpers';
import useFetch from '../../hooks/useFetch';
import { buildRevJetBanner } from '../../utils/revjet';
import { newRelicConstants } from '../../constants';
import {
  adServerClients,
  bannerTypes,
  FORCE_KEVEL,
  IS_KEVEL_TESTING_ENABLED,
  KEVEL_TEST_CATEGORY_GUIDS, KEVEL_TEST_SLUGS
} from '../../constants/constants';
import styles from './SponsoredSkyscraperBanner.module.scss';

const isValidScenario = ({
  schemaId, lastRequestScenario, experienceContext, pageContext, customRenderId, schema, browserId: validBrowserId
}) => {

  if (schemaId.includes('event_browse_sponsored')) {

    return false;
  }

  const makeRequest = shouldMakeApiRequest({
    lastRequestScenario,
    experienceContext,
    pageContext,
    customRenderId,
    schema,
    browserId: validBrowserId
  });

  return makeRequest;
};

const SponsoredSkyscraperBannerComponent = (props) => {
  const [lastRequestScenario, setLastRequestScenario] = useState({});
  const [hasData, setHasData] = useState(false);
  const experienceContext = useContext(ExperienceContext);
  const { browserId, pageContext, customRenderId, slugId } = props;
  const validBrowserId = browserId || experienceContext?.cookie?.adobeCookie?.MCMID;
  let adServer = useConfigService('fs-prop:sponsored-adserver-standard') || adServerClients.PIQ;
  const { mcvisID } = useThdCustomer();
  const callBannerApi = useConfigService('fs:callBannerApi') || false;

  // Ad Server Testing
  const kevelEnabled = useConfigService(IS_KEVEL_TESTING_ENABLED);
  const kevelTestCategoryGuids = useConfigService(KEVEL_TEST_CATEGORY_GUIDS);
  const kevelTestSlugs = useConfigService(KEVEL_TEST_SLUGS);
  const forceKevel = useConfigService(FORCE_KEVEL) || false;

  const channel = experienceContext.channel === 'desktop' ? 'desktop' : 'mobile';

  let { schema } = props;
  const cx = classNames.bind(styles);

  schema = getSchema({ schema, pageContext });
  const schemaId = schema + '__skyscraper';

  if (kevelEnabled || forceKevel) {
    adServer = checkIfPageIsInAdServerTest(forceKevel, pageContext, slugId, kevelTestCategoryGuids, kevelTestSlugs);
  }

  const requestConfig = {
    adServer,
    mcvisID,
    browserId: validBrowserId,
    schema,
    schemaId,
    experienceContext,
    pageContext,
    setHasData,
    bannerType: bannerTypes.SKYSCRAPER,
    slugId,
    ignoreStoreId: true,
    callBannerApi
  };

  // if callBannerApi = true, this hook will run fetch data to bannerapi
  const {
    data: bannerData, status, error, apiRequestTimestamp, apiResponseTimestamp
  } = useFetch(requestConfig);

  useEffect(() => {
    const adType = newRelicConstants.SKYSCRAPER_BANNER;
    if (status === 200) {
      requestConfig.startTime = apiRequestTimestamp;
      setHasData(true);
      buildRevJetBanner({ data: bannerData, requestConfig, adType });
    }
    if (status) {
      triggerNewRelic(adType, `BANNER-API-${status}`);
      // SEND TIMESTAMPS TO CALCULATE API RESPONSE TIME
      triggerNewRelicTimeLog(newRelicConstants.SKYSCRAPER_BANNER_API_RESPONSE, apiRequestTimestamp, apiResponseTimestamp);
      // SEND TIME FROM BANNER INITIATED TO BANNER WINNER RECEIVED
      triggerNewRelicTimeLog(newRelicConstants.SKYSCRAPER_BANNER_AD_INIT_TO_WINNER, apiRequestTimestamp, apiResponseTimestamp);
    }
  }, [status, callBannerApi]);

  useEffect(() => {
    if (!callBannerApi) {
      const validScenario = isValidScenario({
        schemaId, lastRequestScenario, experienceContext, pageContext, customRenderId, schema, browserId: validBrowserId
      });

      if (validScenario && channel === 'desktop') {

        setLastRequestScenario({
          url: window.location.pathname + window.location.search,
          skus: getSkus(pageContext),
        });

        makeApiRequest({
          adServer,
          browserId: validBrowserId,
          schema,
          schemaId,
          experienceContext,
          pageContext,
          setHasData,
          bannerType: 'skyscraper',
          slugId,
          ignoreStoreId: true
        });
      }
    }

  }, [pageContext?.data, schema, slugId, validBrowserId]);

  useEffect(() => {
    initImpressionWatchers();
  }, [hasData]);

  if (!hasData) {
    return null;
  }

  return (
    <div id="sponsored-skyscraper-banner" className={cx('sponsored_skyscraper_banner')} data-component="SponsoredSkyscraperBanner">
      <div id={schemaId} data-testid="sponsored-skyscraper-banner" />
    </div>
  );
};

SponsoredSkyscraperBannerComponent.propTypes = {
  browserId: string,
  customRenderId: string,
  schema: string,
  slugId: string,
  pageContext: shape({
    label: string,
    data: shape(),
    keyword: string,
    isSearch: bool,
    isCategory: bool,
    isBrowse: bool,
  }),
};

SponsoredSkyscraperBannerComponent.defaultProps = {
  browserId: '',
  customRenderId: '',
  schema: '',
  slugId: '',
  pageContext: {},
};

const SponsoredSkyscraperBanner = withDynamicComponent(
  withHydrator(
    {
      id: 'sponsored-skyscraper-banner',
      preserveCtxVal: 'clientStore'
    },
    SponsoredSkyscraperBannerComponent
  )
);

export { SponsoredSkyscraperBanner };
