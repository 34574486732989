import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import classNames from 'classnames/bind';
import { Typography } from '@one-thd/sui-atomic-components';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { Carousel } from '@one-thd/sui-carousel';
import { visNavDataModel } from './dataModel';
import styles from './visual-navigation.scss';
import { VisualNavigationCard } from './VisualNavigationCard';

const VisualNavigation = ({
  componentId,
  componentClass,
  componentPosition,
  isInSection,
  lazyLoad,
  experienceHasSideNav
}) => {
  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass: 'VisualNavigation'
    }
  });

  if (!data || loading || error) {
    return null;
  }

  const cx = classNames.bind(styles);
  const visNavList = data?.component?.visualNavigationList;
  const title = data?.component?.title;
  const isCarousel = data?.component?.overflowBehavior === 'Carousel';
  // should be 'Wrapped' by default in Contentful

  const cardCollectionWrap = (
    <div
      className={cx('card-tile__collection',
        'sui-grid',
        'sui-gap-4',
        'sui-grid-cols-2',
        'sm:sui-grid-cols-3',
        'md:sui-grid-cols-4',
        'lg:sui-grid-cols-4',
        `xl:sui-grid-cols-${experienceHasSideNav ? 4 : 6}`,
        '2xl:sui-grid-cols-6',
      )}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-testid="visual-navigation"
      data-component-id={componentId}
      data-component-name={componentClass}
      data-component-position={componentPosition}
      data-component="VisualNavigation"
    >
      {visNavList.map((item, idx) => (
        <VisualNavigationCard
          item={item}
          key={idx + 1}
          position={idx + 1}
          lazyLoad={lazyLoad}
        />
      ))}
    </div>
  );

  const contents = [];
  if (isCarousel) {
    for (let i = 0; i < visNavList?.length; i += 1) {
      contents.push(
        <div
          className={cx('card-tile__collection')}
          id={`${componentClass}-${componentPosition}-${componentId}`}
          data-testid="visual-navigation"
          data-component-id={componentId}
          data-component-name={componentClass}
          data-component-position={componentPosition}
          data-component="VisualNavigation"
        >
          <VisualNavigationCard
            item={visNavList[i]}
            key={i + 1}
            position={i + 1}
            lazyLoad={lazyLoad}
          />

        </div>
      );
    }
  }

  const cardCarousel = (
    <Carousel
      autoplay={false}
      peek={false}
      slidesPerGroup={2}
      slidesPerView={2}
      spaceBetween={16}
      breakpoints={{
        sm: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 16,
        },
        md: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 16,
        },
        lg: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 16,
        },
        xl: {
          slidesPerView: experienceHasSideNav ? 4 : 6,
          slidesPerGroup: experienceHasSideNav ? 4 : 6,
          spaceBetween: 16,
        }
      }}
    >
      {contents}
    </Carousel>
  );

  const cardCollection = isCarousel ? cardCarousel : cardCollectionWrap;

  return (
    isInSection ? cardCollection
      : (
        <>
          <div className={cx('sui-pb-4')}>
            <Typography variant="h2" weight="display" height="snug" color="primary" uppercase>{title}</Typography>
          </div>
          <ImpressionProvider
            key={componentPosition}
            data={{
              id: 'componentId',
              component: 'Section',
              name: 'Section',
              type: 'content'
            }}
          >
            {cardCollection}
          </ImpressionProvider>
        </>
      )
  );
};

VisualNavigation.displayName = 'VisualNavigation';
VisualNavigation.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  isInSection: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  experienceHasSideNav: PropTypes.bool
};

VisualNavigation.defaultProps = {
  componentClass: '',
  componentPosition: 1,
  isInSection: false,
  lazyLoad: false,
  experienceHasSideNav: false
};

VisualNavigation.dataModel = visNavDataModel;

export { VisualNavigation };