import React, { useEffect } from 'react';
import { string, number, bool } from 'prop-types';
import {
  params,
  shape,
  namedFragment,
  customType,
  string as stringType,
  number as numberType,
  useDataModel,
  typename,
  arrayOf
} from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import { akamaiImageResize } from '../utils/akamaiImageOptimizer';
import { HeroButton } from './HeroButton';
import { VideoHeroImage } from './VideoHeroImage';
import { getImageDetails } from '../utils/getImageDetails';
import styles from './story-hero.scss';

const StoryHero = ({
  componentId, componentClass, componentPosition, hasSidenav
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('contentfulhero.ready');
  }, []);

  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
    skip: !(componentId && componentClass),
  });

  let {
    colorTheme,
    cta,
    description,
    eyebrow,
    link,
    previewImage,
    title,
    altText
  } = data?.component || {};

  const { imageUrl, details } = getImageDetails(previewImage);

  const akamaiImageUrl = akamaiImageResize(imageUrl);

  return (
    <div
      className="story-hero__card-wrapper sui-relative sui-flex sui-h-full"
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-component-id={componentId}
      data-component-name={componentClass}
      data-component-position={componentPosition}
    >
      <div className={`story-hero__card sui-bg-white sui-border-1 sui-border-solid sui-border-[#CCCCCB] box-border
        sui-rounded-[0.2rem] sui-m-0 sui-p-0 sui-overflow-hidden sui-flex sui-flex-col md:sui-flex-row-reverse
        sui-justify-between sui-w-full story-hero__card--${colorTheme}`}
      >
        <div className={`story-hero__image-container sui-flex sui-border-b-1 sui-border-b-solid sui-border-b-[#CCCCCB]
          md:sui-sui-border-l-1 md:sui-border-l-solid md:sui-border-l-[#CCCCCB] md:sui-border-b-0 sui-shrink-0
          md:sui-aspect-square lg:sui-aspect-video md:sui-w-[70%] lg:sui-w-3/5 xl:sui-w-7/12 2xl:sui-w-[66%]
         ${hasSidenav
          ? 'sui-max-h-[275px] md:sui-max-h-[440px]'
          : '2xl:sui-max-h-[400px] md:sui-max-h-[210px] lg:sui-max-h-[280px] xl:sui-max-h-[350px]'}`}
        >
          {imageUrl?.includes('.mp4')
            ? (
              <VideoHeroImage
                imageUrl={imageUrl}
                altText={altText}
                fullWidth
                videoClasses="sui-w-full sui-object-cover md:sui-aspect-square md:sui-h-full"
              />
            ) : (
              <img
                src={akamaiImageUrl}
                alt={`${altText || title}`}
                title={`Image for ${title}`}
                height={details?.height}
                width={details?.width}
                className="story-hero__image sui-aspect-[inherit] sui-object-cover stretchy"
                // eslint-disable-next-line react/no-unknown-property
                fetchpriority="high"
              />
            )}
        </div>
        <div className={`story-hero__info-box sui-flex sui-flex-col sui-justify-center sui-h-full sui-relative sui-p-4
          sui-overflow-y-hidden lg:sui-p-5 md:sui-min-h-[374px] lg:sui-min-h-[384px] xl:sui-min-h-[400px]
          ${!hasSidenav && '2xl:sui-max-h-[400px] md:sui-max-h-[210px] lg:sui-max-h-[280px] xl:sui-max-h-[350px]'}`}
        >
          <div className="story-hero__text-box">
            {eyebrow
              && (
                <Typography
                  height="tight"
                  weight="bold"
                  variant="body-base"
                  color="brand"
                >{eyebrow}
                </Typography>
              )}
            {title
              && (
                <Typography
                  height="none"
                  weight="display"
                  variant="h2"
                  color="primary"
                  uppercase
                >{title}
                </Typography>
              )}
            {description
              && (
                <div className="story-hero__text sui-my-4">
                  <Typography
                    height="tight"
                    weight="bold"
                    variant="body-lg"
                    color="primary"
                  >{description}
                  </Typography>
                </div>
              )}
          </div>
          {link && cta && (
            <HeroButton variant={colorTheme} href={link}>
              {cta}
            </HeroButton>
          )}
        </div>
      </div>
    </div>
  );
};

StoryHero.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentId: string.isRequired,
  componentClass: string.isRequired,
  componentPosition: number,
  hasSidenav: bool
};

StoryHero.defaultProps = {
  componentPosition: 1,
  hasSidenav: false
};

StoryHero.displayName = 'Contentful Story Hero';

StoryHero.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Hero']).isRequired(),
  }).shape({
    Hero: namedFragment({ inline: true, fragmentType: 'Hero' }).shape({
      componentName: stringType(),
      colorTheme: stringType(),
      cta: stringType(),
      description: stringType(),
      eyebrow: stringType(),
      id: stringType(),
      previewImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(
            shape({
              selectedImageDimensions: stringType(),
              selectedImageUrl: stringType()
            })
          )
        }),
        damDownloadedContent: shape({
          details: shape({
            height: numberType(),
            width: numberType(),
            size: numberType(),
          }),
          url: stringType()
        })
      }),
      link: stringType(),
      title: stringType(),
      altText: stringType(),
      __typename: typename('Hero')
    })
  })
};

export { StoryHero };
