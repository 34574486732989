import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { pauseNoHover, pauseHover, playNoHover, playHover } from '../utils/videoIcons';
import styles from './video-hero-image.scss';

const VideoHeroImage = ({
  imageUrl, altText, fullWidth, brandHero, videoClasses
}) => {

  const [videoPaused, setVideoPaused] = useState(false);

  const videoButton = (
    <button
      type="button"
      /* eslint-disable-next-line max-len */
      className="hero-video-button sui-absolute sui-bottom-0 sui-right-0 sui-flex sui-justify-end sui-items-end sui-h-full sui-w-full"
      onClick={() => {
        const videoElement = document.querySelector(`source[src="${imageUrl}"]`)?.parentNode;
        setVideoPaused(!videoPaused);
        /* eslint-disable-next-line no-unused-expressions */
        videoPaused ? videoElement?.play() : videoElement?.pause();
      }}
    >
      <div
        data-testid="play-button"
        className={`play-button sui-h-8 sui-w-8 ${videoPaused ? 'sui-flex' : 'sui-hidden'}`}
      >
        <div className="play-button-hover">{playHover}</div>
        <div className="play-button-no-hover">{playNoHover}</div>
      </div>
      <div
        className={`pause-button sui-h-8 sui-w-8 ${videoPaused ? 'sui-hidden' : 'sui-flex'}`}
      >
        <div className="pause-button-hover">{pauseHover}</div>
        <div className="pause-button-no-hover">{pauseNoHover}</div>
      </div>
    </button>
  );
  const heroImage = (
    <div className={`video-hero-image sui-overflow-hidden sui-relative ${fullWidth
      ? 'sui-w-full' : ''}${brandHero ? ' sui-row-start-1 md:sui-row-start-auto' : ''}`}
    >
      <video
        className={`${videoClasses}`}
        aria-label={`${altText}`}
        autoPlay
        muted
        loop
        controls=""
      >
        <source src={imageUrl} type="video/mp4" />
      </video>
      {videoButton}
    </div>
  );

  return heroImage;
};

VideoHeroImage.propTypes = {
  imageUrl: string,
  altText: string,
  fullWidth: bool,
  brandHero: bool,
  videoClasses: string,
};

VideoHeroImage.defaultProps = {
  imageUrl: '',
  altText: '',
  fullWidth: false,
  brandHero: false,
  videoClasses: ''
};

export { VideoHeroImage };