import React, { useEffect, useCallback } from 'react';
import { string, bool } from 'prop-types';
import { RevJetTag, isViewableEvent } from '@revjet/react-revjet-tag';
import { useImpressionCallback } from '@thd-olt-component-react/impression';

const RevjetBundleReact = ({
  tag,
  plcId,
  keyId,
  omsid,
  recommendationId,
  deliveryWrapper,
  autohide,
  responsive,
  responsiveHeight,
  autoscale,
  deliveryMethod,
  impBannerSize,
  deliveryFormat,
  thdPageId,
  previewApDate,
  previewKeyId,
  previewTag,
  isPreview,
  innervateCarouselIndex,
  customerType,
  carouselPosition,
  shouldTriggerImpression
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('revjet_bundle.ready');
  }, []);

  const tagProps = {
    _tag: isPreview && previewTag ? previewTag : tag,
    _key: isPreview && previewKeyId ? previewKeyId : keyId,
    _plc_id: plcId || '',
    omsid: omsid || '',
    _thd_pageid: thdPageId || '',
    __recommendation_id: recommendationId || '',
    _thd_carousel_index: innervateCarouselIndex,
    _thd_customer_type: customerType,
    _thd_carousel_position: carouselPosition,
    _opts: {
      deliveryWrapper: deliveryWrapper || '',
      autohide,
      responsive,
      responsive_height: responsiveHeight || '',
      autoscale,
      delivery_method: deliveryMethod || '',
      _imp_banner_size: impBannerSize || '',
      delivery_format: deliveryFormat || '',
      _ap_date: isPreview && previewApDate ? previewApDate : ''
      // TODO: vendor said that this field belongs on the root instead of opts
      // does that change anything?
    }
  };

  const { impressionCallback, data, parent, clickID } = useImpressionCallback();

  /* istanbul ignore next */
  const onTrackingEvent = useCallback((type, event) => {
    const properties = event.getProperties ? event.getProperties() : { type };
    if (isViewableEvent(event)) {
      if (properties.num === '900' && shouldTriggerImpression) {
        impressionCallback({
          callbackImpression: data,
          callbackContainer: parent
        });
      }
    } else if (properties.type === 'click_heatmap' && shouldTriggerImpression) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('revjet-bundle.click', {
        properties,
        data,
        parent,
        clickID
      });
    }
  }, [shouldTriggerImpression]);

  return (
    <div className="revjet_bundle">
      <RevJetTag
        tagProperties={tagProps}
        onTrackingEvent={onTrackingEvent}
      />
    </div>
  );
};

RevjetBundleReact.propTypes = {
  autohide: bool,
  autoscale: bool,
  carouselPosition: string,
  customerType: string,
  deliveryFormat: string,
  deliveryMethod: string,
  deliveryWrapper: string,
  impBannerSize: string,
  innervateCarouselIndex: string,
  isPreview: bool,
  keyId: string.isRequired,
  omsid: string,
  plcId: string,
  previewApDate: string,
  previewKeyId: string,
  previewTag: string,
  recommendationId: string,
  responsive: bool,
  responsiveHeight: string,
  shouldTriggerImpression: bool,
  tag: string.isRequired,
  thdPageId: string,
};

RevjetBundleReact.defaultProps = {
  plcId: '',
  omsid: '',
  deliveryWrapper: '',
  autohide: true,
  responsive: true,
  responsiveHeight: '',
  autoscale: true,
  deliveryMethod: '',
  impBannerSize: '',
  recommendationId: '',
  deliveryFormat: '',
  thdPageId: '',
  previewApDate: 'defaultProp',
  previewKeyId: 'defaultProp',
  previewTag: 'defaultProp',
  isPreview: false,
  innervateCarouselIndex: '',
  customerType: 'b2c',
  carouselPosition: '',
  shouldTriggerImpression: false
};

RevjetBundleReact.displayName = 'RevjetBundleReact';

export { RevjetBundleReact };
